import * as React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import BlockContent from "../components/block-content";
import Layout from "../components/layout";
import Speakers from "../components/speakers";
import * as styles from "../styles/events.module.css";

export default function EventsPage() {
  const data = useStaticQuery(graphql`
    query GetAllUpcomingEvents {
      allSanityGroupEvent(sort: { fields: date, order: ASC }) {
        nodes {
          _id
          title
          date(formatString: "D MMMM YYYY")
          speakers {
            _id
            personTitle
            forename
            surname
          }
          href
          _rawBody
        }
      }
      sanityPage(_id: { eq: "70bb7028-bb2a-496f-a528-0ef3d10d6554" }) {
        id
        title
        description
        _rawIntroSentence
        _rawSurroundBefore
        _rawSurroundAfter
        callToAction {
          href
          linkText
        }
      }
    }
  `);

  const events = data.allSanityGroupEvent.nodes;
  // find day after event
  events.forEach(function (event) {
    const eventDate = new Date(event.date);
    const eventOver = new Date(eventDate);
    eventOver.setDate(eventDate.getDate() + 1);
    event.nextDay = Number(eventOver);
  });
  // display events in the past if there are no events scheduled in the future
  const futureEvents = events.filter(
    (event) => new Date(event.nextDay) >= new Date()
  );
  const pastEvents = events.filter(
    (event) => new Date(event.nextDay) < new Date()
  );
  let eventsToDisplay = [];
  futureEvents.length
    ? (eventsToDisplay = futureEvents.slice(0, 2))
    : (eventsToDisplay = pastEvents.slice(-3).reverse());
  // filter for events with a registration link
  const detailedEvents = futureEvents.filter((event) => event.href !== null);
  // filter for events without a registration link
  const stubEvents = futureEvents.filter((event) => event.href === null);

  const page = data.sanityPage;

  return (
    <Layout title={page.title} description={page.description}>
      <BlockContent blocks={page._rawIntroSentence || []} />
      <BlockContent blocks={page._rawSurroundBefore || []} />
      {detailedEvents.map((event) => (
        <article className="event-item" key={event._id}>
          <h3>{event.title}</h3>
          <time>{event.date}</time>
          <Speakers speakersList={event.speakers} />
          <BlockContent blocks={event._rawBody || []} />
          <a href={event.href} class="action">
            Register
          </a>
        </article>
      ))}{" "}
      {/* show subheading if more than one kind of event */}
      {detailedEvents.length && stubEvents.length ? (
        <div class="heading-container">
          <h2>More events</h2>
          <div class="heading-line"></div>
        </div>
      ) : null}
      <ul>
        {" "}
        {stubEvents.map((event) => (
          <li key={event._id}>
            <time>{event.date}</time> -{" "}
            {event.title ? <strong>{event.title}</strong> : " Title TBA"}
            {event.speakers.length ? (
              <span class="speakers">
                {" "}
                -
                <Speakers speakersList={event.speakers} />
              </span>
            ) : null}
          </li>
        ))}
      </ul>
      <BlockContent blocks={page._rawSurroundAfter || []} />
      <Link class="action" to={page.callToAction.href}>
        {page.callToAction.linkText}
      </Link>
      {/* Show past events if no events scheduled in future */}
      {!futureEvents.length ? (
        <div class={`heading-container past ${styles.past}`}>
          <h2>Recent events</h2>
          <div class="heading-line"></div>
        </div>
      ) : (
        ""
      )}
      {!futureEvents.length ? (
        <ul>
          {eventsToDisplay.map((event) => (
            <li key={event._id}>
              <time>{event.date}</time> -{" "}
              <strong>
                {event.href ? (
                  <a href={event.href}>{event.title}</a>
                ) : (
                  event.title
                )}
              </strong>{" "}
              -
              <span class="speakers">
                {event.speakers.map((speaker) => (
                  <span className="speaker" key={speaker._id}>
                    {speaker.personTitle} {speaker.forename} {speaker.surname}
                  </span>
                ))}
              </span>
            </li>
          ))}
        </ul>
      ) : (
        ""
      )}
      {!futureEvents.length ? (
        <p>
          <Link to="../past-events/">More events</Link>
        </p>
      ) : (
        <p>
          <Link to="../past-events/">Past events</Link>
        </p>
      )}
    </Layout>
  );
}
